.gpt3__possibility {
  display: flex;
  flex-direction: row;
}
::-webkit-file-upload-button {
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

.gpt3__possibility-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-right: 2rem;
}

.gpt3__possibility-image img {
  width: 100%;
  height: 100%;
}

.gpt3__possibility-content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.form--input {
  width: 100%;
  height: 56px;
  font-family: var(--font-family);
  border-radius: 4px;
  position: relative;
  background-color: #71e5ff;
  transition: 0.3s all;
}
form--input:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.form--button {
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}
.form--button:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.gpt3__possibility-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71e5ff;
}

.gpt3__possibility-content h4:last-child {
  color: #ff8a71;
}

.gpt3__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}

.gpt3__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .gpt3__possibility {
    flex-direction: column;
  }

  .gpt3__possibility-image {
    margin: 1rem 0;
  }

  .gpt3__possibility-image img {
    width: unset;
    height: unset;
  }

  .gpt3__possibility-content {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__possibility-image img {
    width: 100%;
    height: 100%;
  }
}
